<template>
  <div class="my-4 text-center" style="width: 600px; margin: 0 auto">
    <b-row v-for="(channel, index) in channels" :key="index" class="mb-4">
      <b-col>
        <div class="label w-100">
          <span class="text-left">
            {{ getChannelLabel(channel.id) }}
          </span>
          
        </div>
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="channel.iframe_url"
          allowfullscreen
        ></b-embed>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import api from "@/services/api";

const channelLabels = {
  1: "CALCIO - FIFA '21",
  2: "BASKET - NBA 2K21"
};

export default {
  data() {
    return {
      channels: {},
    };
  },
  mounted(){
    this.init();
  },
  methods: {
    init(){
     api.getCanali((response) => {
        this.channels = response;
      });
    },
    getChannelLabel(id){
      return channelLabels[id];
    }
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
