import Vue from "vue";
import VueRouter from "vue-router";

import Default from "@/components/layouts/Default.vue";
import FullContent from "@/components/layouts/FullContent.vue";

import Login from "@/components/Login.vue";
//import Schedule from "@/components/Schedule.vue";
import ScheduleWeb from "@/components/ScheduleWeb.vue";
import Statistiche from "@/components/Statistiche.vue";
import Monitor from "@/components/Monitor.vue";
import Grafica from "@/components/Grafica.vue";
import Channel from "@/components/Channel.vue";
import Regolamenti from "@/components/Regolamenti.vue";
import Scores from "@/components/Scores.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
  //  component: Schedule,
    component: ScheduleWeb,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/web",
    name: "web",
    component: ScheduleWeb,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/palinsesto",
    name: "stats",
    component: Statistiche,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/monitor",
    name: "monitor",
    component: Monitor,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/canale",
    name: "canale",
    component: Channel,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/regolamenti",
    name: "regolamenti",
    component: Regolamenti,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/scores",
    name: "scores",
    component: Scores,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/grafica/:channelId",
    name: "grafica",
    component: Grafica,
    meta: { layout: FullContent, requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("user")) {
      next();
    } else {
      //let user = JSON.parse(localStorage.getItem("user"));
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
