<template>
  <div>
    <div id="statisticheComponent" class="my-3">
    <!--  <b-row id="layout-btn" no-gutters>
        <b-col
          cols="6"
          @click="layout = 'palinsesto'"
          :class="layout == 'palinsesto' ? 'active' : ''"
        >
          PALINSESTO
        </b-col>
        <b-col
          cols="6"
          @click="layout = 'scores'"
          :class="layout == 'scores' ? 'active' : ''"
        >
          RISULTATI
        </b-col>
      </b-row> -->
      <b-row v-if="layout == 'palinsesto'">
        <b-col :md="2">
          <div class="menu">
            <div class="head">DISCIPLINE</div>
            <div
              v-for="disciplina in discipline"
              :key="disciplina.sport_id"
              @click="selectDisciplina(disciplina.sport_id)"
              :class="classSelectedDisciplina(disciplina.sport_id)"
            >
              {{ disciplina.sport_name }}
            </div>
          </div>
        </b-col>
        <b-col>
          <b-table-simple
            :sticky-header="'70vh'"
            bordered
            responsive
            striped
            style="overflow-y: scroll"
          >
            <b-thead v-if="headerMarketOutcomeIds">
              <b-tr style="height: 5vh">
                <b-th rowspan="2" style="width: 50px">ORA</b-th>
                <b-th rowspan="2" style="min-width: 200px">MATCH</b-th>
                <b-th
                  class="d-none d-md-table-cell"
                  style="text-align: center; text-transform: uppercase"
                  v-for="(
                    headerMarketOutcomeId, indexHeaderMarOutIds
                  ) in headerMarketOutcomeIds"
                  :key="indexHeaderMarOutIds"
                  :colspan="headerMarketOutcomeId.colspan"
                >
                  {{ marketLabels[parseInt(headerMarketOutcomeId.market)] }}
                </b-th>

                <b-th rowspan="2"></b-th>
              </b-tr>
              <b-tr style="height: 5vh; top: 5vh">
                <b-th
                  class="d-none d-md-table-cell"
                  style="text-align: center"
                  v-for="(marketOutcomeId, indexMarOutIds) in marketOutcomeIds[
                    selectedDisciplina
                  ]"
                  :key="indexMarOutIds"
                >
                  {{ marketOutcomeId.outcomeLabel }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody
              v-for="(eventi, tournament_id) in sortEventi(
                avvenimenti[selectedDisciplina]
              )"
              :key="tournament_id"
            >
              <b-tr @click="addOrRemove(tournament_id)">
                <b-td colspan="9">
                  {{ eventi[0].tournament_name }}
                  <b-icon
                    stacked
                    icon="chevron-down"
                    style="width: 20px; float: right"
                  ></b-icon>
                </b-td>
              </b-tr>
              <b-tr
                v-for="evento in eventi"
                :key="evento.id"
                v-show="checkTest(tournament_id)"
              >
                <b-td v-if="evento.is_live" class="align-middle">
                  <span class="live">LIVE</span>
                </b-td>
                <b-td v-else class="align-middle">
                  {{
                    evento.begin
                      | moment("timezone", "Europe/Rome", "DD/MM H:mm")
                  }}
                </b-td>
                <b-td class="align-middle">{{ evento.description }}</b-td>
                <b-td
                  v-for="(marketOutcomeId, indexMarOutIds) in marketOutcomeIds[
                    selectedDisciplina
                  ]"
                  :key="indexMarOutIds"
                  class="d-none d-md-table-cell quota"
                  :class="[
                    classMarketOutcome(evento, marketOutcomeId),
                    marketOutcomeId.param ? '' : 'font-weight-bold',
                  ]"
                >
                  <span class="arrow-up"></span>
                  {{ getMarketOutcome(evento.markets, marketOutcomeId) }}
                  <span class="arrow-down"></span>
                </b-td>
                <b-td
                  class="more align-middle"
                  @click="selectAvvenimento(evento)"
                >
                  <b-icon icon="plus-circle" variant="primary"></b-icon>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-if="layout == 'scores'">
        <b-col>
          <Scores></Scores>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="overlayAvvenimento"
      size="lg"
      class="p-0"
      hide-footer
      :headerClass="'p-2 background-primary text-center'"
      :header-close-variant="'light'"
      :titleClass="'w-100'"
      :bodyClass="'p-0'"
      :modalClass="'overflow-auto mt-5'"
      @shown="setQuoteMarkets()"
      @hidden="resetQuoteInterval()"
    >
      <template #modal-title>
        <div class="text-light">
          {{ selectedAvvenimento.description }}
          <br />
          <span style="color: red; font-weight: bold">
            {{ selectedAvvenimento.result_total }}
          </span>
        </div>
      </template>
      <div style="overflow-y: scroll; height: 80vh; overflow-x: hidden">
        <Quote ref="QuoteModal"></Quote>
      </div>
    </b-modal>
  </div>
</template>


<script>
import api from "@/services/api";

import Quote from "@/components/schedule/Quote.vue";
import Scores from "@/components/Scores.vue";
const marketLabels = {
  2: "testa a testa",
  3: "esito finale 1x2",
  10055: "under/over",
};

const marketOutcomeIds = {
  1: [
    {
      marketId: 3,
      outcomeId: 1,
      outcomeLabel: "1",
    },
    {
      marketId: 3,
      outcomeId: 2,
      outcomeLabel: "X",
    },
    {
      marketId: 3,
      outcomeId: 3,
      outcomeLabel: "2",
    },
    {
      marketId: 10055,
      outcomeId: 37,
      outcomeLabel: "",
      param: true,
    },
    {
      marketId: 10055,
      outcomeId: 37,
      outcomeLabel: "OVER",
    },
    {
      marketId: 10055,
      outcomeId: 38,
      outcomeLabel: "UNDER",
    },
  ],
  2: [
    {
      marketId: 2,
      outcomeId: 39,
      outcomeLabel: "1",
    },
    {
      marketId: 2,
      outcomeId: 40,
      outcomeLabel: "2",
    },
    {
      marketId: 10055,
      outcomeId: 37,
      outcomeLabel: "",
      param: true,
    },
    {
      marketId: 10055,
      outcomeId: 37,
      outcomeLabel: "OVER",
    },
    {
      marketId: 10055,
      outcomeId: 38,
      outcomeLabel: "UNDER",
    },
  ],
};

const fullTimePeriodNumber = {
  1: 1,
  2: 0,
};
export default {
  data: () => {
    return {
      loading: false,
      avvenimenti: {},
      discipline: {},
      interval: null,
      intervalQuote: null,
      marketOutcomeIds: marketOutcomeIds,
      headerMarketOutcomeIds: {},
      marketLabels: marketLabels,
      mappedMarkets: {},
      selectedDisciplina: 1,
      selectedAvvenimento: null,
      tournamentToShow: [],
      layout: "palinsesto",
    };
  },
  components: {
    Quote,
    Scores,
  },
  mounted() {
    this.setTableHeader();
    this.getAvvenimenti();
    this.interval = setInterval(() => this.getAvvenimenti(), 5 * 60 * 1000);
  },
  computed: {
    isMobile: () => window.innerWidth <= 760,
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
    if (this.intervalQuote != null) clearInterval(this.intervalQuote);
  },
  methods: {
    checkTest(tournament_id) {
      return this.tournamentToShow.includes(parseInt(tournament_id));
    },
    addOrRemove(value) {
      var index = this.tournamentToShow.indexOf(parseInt(value));

      if (index === -1) {
        this.tournamentToShow.push(parseInt(value));
      } else {
        this.tournamentToShow.splice(index, 1);
      }
    },
    resetQuoteInterval() {
      clearInterval(this.intervalQuote);
    },
    selectDisciplina(idDisciplina) {
      this.layout = "palinsesto";
      this.selectedDisciplina = idDisciplina;
      this.setTableHeader();
    },
    classSelectedDisciplina(idDisciplina) {
      if (this.selectedDisciplina == idDisciplina) return "active";
    },
    selectAvvenimento(avv) {
      this.selectedAvvenimento = avv;
      this.$bvModal.show("overlayAvvenimento");
      this.intervalQuote = setInterval(() => this.getAvvenimento(), 10 * 1000);
    },
    getAvvenimento() {
      api.getAvvenimento((res) => {
        if (res && res.markets) this.setQuoteMarkets(false, res.markets);
      }, this.selectedAvvenimento.id);
    },
    setQuoteMarkets(reloadMarkets = true, markets = null) {
      if (markets == null) markets = this.selectedAvvenimento.markets;

      this.$nextTick(() => {
        this.$refs["QuoteModal"].setMarkets(markets, reloadMarkets);
      });
    },
    sortEventi(eventi) {
      var filtered = this._.chain(eventi)
        .sortBy("begin")
        .groupBy("tournament_id")
        .value();
      return filtered;
    },
    setTableHeader() {
      this.headerMarketOutcomeIds = this._.chain(
        this.marketOutcomeIds[this.selectedDisciplina]
      )
        .countBy("marketId")
        .map((value, key) => {
          return { colspan: value, market: key };
        })
        .value();
    },

    getAvvenimenti() {
      this.loading = true;
      api.getAvvenimenti((data) => {
        this.avvenimenti = this._.groupBy(data, "sport_id");

        this.tournamentToShow = this._.chain(data)
          .filter({ is_live: 1 })
          .map("tournament_id")
          .uniq()
          .value();

        this.discipline = this._.chain(data)
          .map((item) => {
            return { sport_name: item.sport_name, sport_id: item.sport_id };
          })
          .uniqBy("sport_id")
          .value();
        this.loading = false;
      }, 1);
    },

    checkIfMarketExists(markets, marketOutcomeId) {
      return (
        markets[marketOutcomeId.marketId] &&
        markets[marketOutcomeId.marketId].periods[
          fullTimePeriodNumber[this.selectedDisciplina]
        ] &&
        markets[marketOutcomeId.marketId].periods[
          fullTimePeriodNumber[this.selectedDisciplina]
        ].outcomes[marketOutcomeId.outcomeId]
      );
    },

    getMarketOutcome(markets, marketOutcomeId) {
      if (this.checkIfMarketExists(markets, marketOutcomeId))
        var outcome =
          markets[marketOutcomeId.marketId].periods[
            fullTimePeriodNumber[this.selectedDisciplina]
          ].outcomes[marketOutcomeId.outcomeId];

      if (outcome)
        return marketOutcomeId.param
          ? outcome.outcome_param_1
          : outcome.outcome_odd.toFixed(2);

      return "-";
    },

    classMarketOutcome(evento, marketOutcomeId) {
      if (this.checkIfMarketExists(evento.markets, marketOutcomeId)) {
        var outcomeOdd =
          evento.markets[marketOutcomeId.marketId].periods[
            fullTimePeriodNumber[this.selectedDisciplina]
          ].outcomes[marketOutcomeId.outcomeId];

        var key =
          evento.id +
          "_" +
          marketOutcomeId.marketId +
          "_" +
          marketOutcomeId.outcomeListId;
        var returnClass = "";
        if (this.mappedMarkets[key]) {
          if (this.mappedMarkets[key] > outcomeOdd) {
            returnClass = "down";
          } else if (this.mappedMarkets[key] < outcomeOdd) {
            returnClass = "up";
          }
        }
        this.mappedMarkets[key] = outcomeOdd;
        return returnClass;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/sass/variables";

#layout-btn {
  //min-height: 5vh;
  margin-bottom: 5px;
  & > div {
    min-height: 5vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid grey;
    font-weight: bold;
    font-size: 15px;
    background-color: $warn;
    &.active {
      background-color: $accent;
    }
    &:hover:not(.active) {
      filter: brightness(80%);
    }
  }
}

#statisticheComponent {
  min-height: 40vh;
  font-size: 12px;
}

@media (max-width: 760px) {
  #statisticheComponent {
    min-height: 40vh;
  }
}

.menu {
  width: 100%;
  border: 2px solid gray;
  & > div {
    padding: 2vh;
    border: 1px solid rgba(0, 0, 0, 0.125);
    cursor: pointer;
    &.head {
      background-color: $accent;
      font-size: 12px;
      font-weight: bold;
    }
    &.active {
      background-color: $warn;
    }
  }
}

.b-table-sticky-header {
  overflow-x: hidden;
  > .table.b-table > thead tr {
    position: sticky;
    th {
      background-color: $primary;
      color: white;
      vertical-align: middle;
      border: 2px solid #dee2e6;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid green;
  display: none;
  position: absolute;
  bottom: 40%;
  right: 8px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid red;
  display: none;
  position: absolute;
  top: 40%;
  left: 8px;
}

td.quota {
  position: relative;
  text-align: center;
  width: 80px;
  vertical-align: middle;
  &.up {
    .arrow-down {
      display: none;
    }

    .arrow-up {
      display: block;
    }
  }

  &.down {
    .arrow-down {
      display: block;
    }

    .arrow-up {
      display: none;
    }
  }
}
.more {
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    background-color: darken(whitesmoke, 10%);
  }
}
.live {
  background-color: red;
  color: white;
  padding: 2px 6px;
  border-radius: 5px;
}
.avvenimento {
  display: flex;
  &:nth-child(even) {
    background-color: whitesmoke;
  }
  div {
    border: 1px solid gray;
    padding: 10px;
    &.centered {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>