import ajaxCall from "./ajaxCall";
import {
  endpointLogin,
  endpointAvvenimenti,
  endpointCanali,
  endpointScores
} from "../../constants";

export default {
  getAvvenimenti(cb, outcomes = 0, saleWay) {
    let d = {
      outcomes: outcomes,
      sale_way: saleWay || null
    }
    ajaxCall.sendGet(endpointAvvenimenti, d).then((response) => cb(response.data));
  },

  getAvvenimento(cb, idAvvenimento) {
    ajaxCall
      .sendGet(endpointAvvenimenti + "/" + idAvvenimento)
      .then((response) => cb(response.data));
  },

  getCanali(cb) {
    ajaxCall.sendGet(endpointCanali).then((response) => cb(response.data));
  },

  getScores(cb, date) {
    let d = {
      date: date
    }
    ajaxCall.sendGet(endpointScores, d).then(response => cb(response.data));
  },
  login(data, cb) {
    ajaxCall.sendPost(endpointLogin, data).then((response) => cb(response));
  },
};
