let DEVELOPMENT = process.env.NODE_ENV === "development";
let endpoint = DEVELOPMENT
  ? process.env.VUE_APP_API_ENDPOINT
  : "https://api.ejoyesports.com/api/";

let endpointBet = endpoint + "esports/bet/";

module.exports = {
  endpointEjoyesports: "https://live.ejoyesports.com/",
  endpointLogin: endpoint + "auth/web-login",
  endpointAvvenimenti: endpointBet + "avvenimenti",
  endpointCanali: endpointBet + "canali",
  endpointScores: endpointBet + "score-list",
};
