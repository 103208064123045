<template>
  <div id="app" >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import DefaultLayout from "./components/layouts/Empty.vue";
const default_layout = DefaultLayout;
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
  },
  mounted() {
    if (!this._.isUndefined(localStorage.language))
      this.$i18n.locale = localStorage.language;
  },
};
</script>


<style lang="scss">
@import "@/sass/variables";
@import url("https://fonts.googleapis.com/css?family=Nunito");

body {
  background-image: url("./assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: $font-family-sans-serif!important;
}
#app{
  overflow: hidden;
}
#content {
  background-color: $body-bg;
}

.list-group-item {
  cursor: pointer;
  padding: 0.5rem 1rem !important;
  border-radius: 0;
  &.active {
    background-color: $warn !important;
    border-color: $warn !important;
    color: $dark-text !important;
  }
  .row > div {
    padding: 5px;
  }
}

.bg-accent {
  background-color: $accent;
}

.background-primary {
  background-color: $primary;
}

</style>
