<template>
  <b-nav-item-dropdown right id="localeDropdown" class="d-flex align-items-center">
    <template #button-content>
      <img class="d-none d-md-block" src="@/assets/translation.png" />
      <span class="d-block d-md-none">Language</span>
    </template>
    <b-dropdown-item
      v-for="(lang, i) in langs"
      :key="`Lang${i}`"
      @click="selectLanguage(lang.id)"
    >
      {{ lang.label }}
    </b-dropdown-item>
  </b-nav-item-dropdown>

  <!--<b-form-select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </b-form-select>-->
</template>

<script>
export default {
  data() {
    return {
      langs: [
        { id: "en", label: "english" },
        { id: "it", label: "italiano" },
      ],
    };
  },
  methods: {
    selectLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.language = lang;
    },
  },
};
</script>

<style lang="scss">
#localeDropdown {
  img {
    height: 30px;
  }
  a {
    display: flex;
    align-items: center;
  }
}
</style>