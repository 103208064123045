<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-skeleton-table
        :rows="5"
        :columns="1"
        :table-props="{ bordered: true, striped: false }"
      ></b-skeleton-table>
    </template>

    <div>
      <b-list-group class="mb-2">
        <b-list-group-item
          v-b-toggle="'collapse-menu-' + subject"
          class="list-group-header"
        >
          <b-row>
            <b-col> {{ subject }} </b-col>

            <b-col cols="1">
              <b-icon stacked icon="chevron-down"></b-icon>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-collapse :id="'collapse-menu-' + subject" :visible="!isMobile">
          <b-list-group-item
            v-for="(item, index) in items"
            :key="index"
            @click="select(item)"
            :class="classSelected(item)"
            class="py-0 px-1"
          >
            <b-row no-gutters>
              <b-col cols="4" class="ora">
                <span v-if="item.is_live" class="live-point"></span>
                {{ item.begin | moment("timezone", "Europe/Rome", "H:mm") }}
              </b-col>
              <b-col class="canale"> {{ item.channel_name }}</b-col>
            </b-row>

            <b-row no-gutters>
              <b-col class="avvenimento"> {{ item.description }}</b-col>
            </b-row>
          </b-list-group-item>
        </b-collapse>
      </b-list-group>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  props: ["isMobile", "loading", "items", "selectedItem", "subject"],
  methods: {
    select(item) {
      this.$emit("select", item);
    },
    classSelected(item) {
      if (item && this.selectedItem && item.id == this.selectedItem.id)
        return "active";
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.list-group {
  border-radius: 0 !important;
  border: 4px solid gray;
}
.list-group-header {
  background-color: $accent !important;
  border-color: $accent !important;
  border-radius: 0 !important;
  padding: 0.8rem 1.25rem !important;
  text-transform: uppercase;
  font-weight: bold;
}

.active {
  font-weight: bold;
}

.ora {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.canale {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.live {
  background-color: red;
  color: white;
  font-size: 15px;
  padding: 2px 6px;
  border-radius: 5px;
}

.live-point {
  background-color: red;
  padding: 5px;
  border-radius: 50%;
  margin-right: 2px;
}
.avvenimento {
  font-size: 14px;
}
.torneo {
  text-transform: uppercase;
  font-size: 12px;
}
</style>