<template>
  <b-navbar
    id="navbarComponent"
    toggleable="lg"
    sticky
    class="navbar-dark row"
    style="z-index: 9"
  >
    <router-link :to="{ name: 'home' }" exact>
      <b-navbar-brand>
        <img src="@/assets/logobetting.png" />
      </b-navbar-brand>
    </router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto py-2 px-4">
       <!--  <b-nav-item class="button-regolamenti" @click="pushRoute('regolamenti')"
          >Regolamenti
        </b-nav-item>
        <b-nav-item>
          <b-button @click="openEjoyesports()">
            <img src="@/assets/esportslivescore.png" style="height: 4vh" />
          </b-button>
        </b-nav-item>
       
        <b-nav-item class="d-flex align-items-center">
          <b-button @click="openEjoyesportsRegolamenti()">
            <span>Regolamenti</span>
          </b-button>
        </b-nav-item>
        -->

        <Locale></Locale>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Locale from "@/components/Locale.vue";
import { endpointEjoyesports } from "../../../constants";

export default {
  components: {
    Locale,
  },
  methods: {
    pushRoute(routeName) {
      if (this.$route.name != routeName) {
        this.$router.push({
          name: routeName,
        });
      }
    },
    openEjoyesports() {
      window.open(endpointEjoyesports, "_blank").focus();
    },

    openEjoyesportsRegolamenti() {
      window.open(endpointEjoyesports + "regolamenti", "_blank").focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

a {
  color: black !important;
  &:hover {
    color: black !important;
    text-decoration: underline !important;
  }
}

#navbarComponent {
  height: 10vh;
  background-color: $primary;
  .navbar-toggler {
    padding: 5px;
  }
  .navbar-collapse {
    background-color: $primary;
    .button-regolamenti {
      padding: 10px;
      a.nav-link {
        color: $accent-darker !important;
      }
    }
  }
  //border-bottom: 2px solid $accent;

  a {
    color: $light-primary-text;
    img {
      height: 6vh;
    }
    @media (max-width: 760px) {
      img {
        height: 5vh;
      }
    }
    &.dropdown-item {
      color: $dark-primary-text;
    }
  }
}
</style>