<template>
  <div id="loginFormContainer">
    <b-card>
      <b-card-text>
        <img src="@/assets/logo.png" class="mb-5" />
        <br />
        <span style="color: red">{{ errorMsg }}</span>

        <b-form @submit="onSubmit">
          <b-form-group id="input-group-1" label="Username" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="username"
              required
              autofocus
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Password:"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              type="password"
              v-model="password"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" class="mt-2">Login</b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return {
      username: "",
      password: "",
      errorMsg: null,
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (this.password.length > 0) {
        api.login(
          { username: this.username, password: this.password },
          (response) => {
            if (response.error) {
              this.errorMsg = response.error.message;
            } else {
              localStorage.setItem("user", JSON.stringify(response.data));
              this.$router.push({
                name: "home",
              });
            }
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

#loginFormContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
img {
  max-width: 200px;
}
.card {
  background-color: $primary;
  color: $light-text;
  min-width: 500px;
  border: 2px solid $accent;
  border-radius: 20px;
  padding: 2vh;
  .card-body {
    text-align: center;
    input {
      text-align: center;
    }
    button {
      background-color: $accent;
      color: $primary;
      &:hover {
        background-color: $accent-darker;
      }
    }
  }
}
</style>