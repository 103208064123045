<template>
  <div class="container">
    <Navbar></Navbar>
    <SubNavbar></SubNavbar>

    <div id="Aside">
      <b-button
        :href="
          $t('locale') == 'en'
            ? '/ENG_EJOY_ESPORTS.pdf'
            : '/ITA_EJOY_ESPORTS.pdf'
        "
        target="_blank"
        id="AsideBtn"
      >
        {{
          $t("locale") == "en" ? "Executive Summary PDF" : "Presentazione PDF"
        }}
      </b-button>
    </div>
    <div id="content" class="row default-layout">
      <div class="col">
        <slot />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/layouts/Navbar.vue";
import SubNavbar from "@/components/layouts/SubNavbar.vue";
import Footer from "@/components/layouts/Footer.vue";

export default {
  components: {
    Navbar,
    SubNavbar,
    Footer,
  },
};
</script>

<style lang="scss">
@import "@/sass/variables";
#content.default-layout {
  min-height: 75vh;
}

#Aside {
  position: absolute;
  top: 5vh;
  left: 5vh;
}

#AsideBtn {
  background-color: $accent !important;
  border-color: $accent !important;
  color: black !important;
  border-radius: 50%;
  font-weight: bold;
  height: 12vh;
  width: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>