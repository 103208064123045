<template>
  <b-card id="RetailCardTabs" no-body class="my-4">
    <b-tabs content-class="p-2" fill card>
      <b-tab title="LIVE VIDEO WALL" lazy>
        <b-row>
          <b-col class="background-primary p-2">
            <b-row class="no-gutters" v-if="channels">
              <b-col md="6" v-for="channel in channels" :key="channel.id">
                <div
                  :style="
                    channel.id == 1 ? 'margin-right: 4px' : 'margin-left: 4px'
                  "
                >
                  <iframe
                    v-if="
                      channel.event && channel.event.streaming_link !== null
                    "
                    :src="channel.event.streaming_link"
                    class="w-100"
                    style="height: 300px; background-color: black"
                    frameBorder="0"
                  ></iframe>
                  <div
                    v-else
                    class="w-100"
                    style="
                      height: 300px;
                      background-position: center;
                      background-size: cover;
                    "
                    :style="
                      'background-image: url(' +
                      require('@/assets/monitor-bg.png') +
                      ')'
                    "
                  />
                </div>
                <div
                  :style="
                    channel.id == 1 ? 'margin-right: 4px' : 'margin-left: 4px'
                  "
                >
                  <GraficaVue
                    :avvenimento="channel.event"
                    class="w-100"
                    style="height: 300px; border: 0; overflow: hidden"
                  />

                  <!--  <iframe
                    :src="'/grafica/' + channel.id"
                    class="w-100"
                    style="height: 300px; border: 0; overflow: hidden"
                  >
                  </iframe> -->
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="VISUAL COMPACT ODDS" lazy>
        <b-row class="p-2" v-if="channels">
          <b-col
            cols="12"
            v-for="channel in channels"
            :key="channel.id"
            class="text-center pagina-grafica"
            style="position: relative"
          >
            <b-link :href="getPaginaGrafica(channel.id, true)" target="_blank">
              <span class="watch-live">
                <b-icon icon="search" scale="4"></b-icon>
              </span>
              <img
                class="background-primary w-75 m-2 p-1"
                :src="
                  require('@/assets/visualizzazione/ch' +
                    channel.id +
                    'video.png')
                "
              />
            </b-link>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="INFO GRAPHICS ODDS" lazy>
        <b-row class="p-2" v-if="channels">
          <b-col
            cols="12"
            v-for="channel in channels"
            :key="channel.id"
            class="text-center pagina-grafica"
            style="position: relative"
          >
            <b-link :href="getPaginaGrafica(channel.id)" target="_blank">
              <span class="watch-live">
                <b-icon icon="search" scale="4"></b-icon>
              </span>
              <img
                class="background-primary w-75 m-2 p-1"
                :src="
                  require('@/assets/visualizzazione/ch' + channel.id + '.png')
                "
              />
            </b-link>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>


<script>
import api from "@/services/api";
import GraficaVue from "./Grafica.vue";

const liveEjoyVisualizzazione = "https://live.ejoyesports.it/visualizzazione/";

export default {
  data: () => {
    return {
      interval: null,
      channels: [
        {
          id: 1,
          event: null,
        },
        {
          id: 2,
          event: null,
        },
      ],
    };
  },
  components: {
    GraficaVue,
  },
  computed: {
    isMobile: () => window.innerWidth <= 760,
  },
  mounted() {
    this.init();
    this.interval = setInterval(() => this.init(), 60 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },

  methods: {
    init() {
      api.getAvvenimenti(
        (res) => {
          let ch = this._.filter(res, {
            is_live: 1,
          });

          this.setLiveEvent(0, ch[0]);
          this.setLiveEvent(1, ch[1]);
        },
        0,
        "web"
      );
    },
    setLiveEvent(channelIndex, event){
      if (
            this.channels[channelIndex].event == null ||
            (this.channels[channelIndex].event &&
            event &&
              this.channels[channelIndex].event.id !== event.id)
          )
            this.$set(this.channels[channelIndex], "event", event);
          else this.$set(this.channels[channelIndex], "event", null);
    },
    getPaginaGrafica(channelId, video = false) {
      if (video) return liveEjoyVisualizzazione + "video?canale=" + channelId;
      else return liveEjoyVisualizzazione + "?canale=" + channelId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";
.row > div {
  padding: 0 5px;
}
.pagina-grafica {
  .watch-live {
    display: none;
  }
  &:hover {
    opacity: 0.8;
    .watch-live {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      color: black;
    }
  }
}
</style>

<style lang="scss">
@import "@/sass/variables";
#RetailCardTabs {
  border: 2px solid $primary;
  .card-header {
    background-color: $primary !important;
    border-radius: 0;
    font-weight: bold;
    a {
      color: $accent !important;
    }
    .active {
      color: $primary !important;
    }
  }
}
</style>