import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import axios from "axios";
import VueAxios from "vue-axios";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVueIcons } from "bootstrap-vue";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import 'moment/locale/it';
//moment.locale("it");
moment.tz.setDefault("UTC");

Vue.config.productionTip = false;

Vue.use(VueMoment, {
  moment
});
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
