<template>
  <div id="quoteComponent">
    <div v-if="markets">
      <div v-for="(market, marketId) in markets" :key="marketId">
        <b-row>
          <b-col class="scommessa">
            {{ market.market_name }}
          </b-col>
        </b-row>

        <b-row
          class="no-gutters"
          v-for="(period, periodIndex) in market.periods"
          :key="periodIndex"
        >
          <b-col cols="12" class="periodo accent-border-top text-center">
            {{ period.period_name }}
          </b-col>

          <b-col
            class="esito"
            v-for="(outcome, outcomeId) in period.outcomes"
            :key="outcomeId"
            :md="calcColspanEsiti(period.outcomes)"
            :style="
              period.status != 2 ||
              outcome.outcome_status == 4 ||
              outcome.outcome_status == 5
                ? 'opacity: 0.4'
                : ''
            "
          >
            <div class="nome">
              {{ outcome.outcome_name }} {{ outcome.outcome_param_1 }}
            </div>
            <div
              class="quota"
              :class="[
                classMarketOutcome(
                  market.market_list_id,
                  period.periods_id,
                  outcome.outcome_list_id,
                  outcome.outcome_odd
                ),

                period.status != 2 ||
                outcome.outcome_status == 4 ||
                outcome.outcome_status == 5
                  ? 'deleted'
                  : '',
              ]"
            >
              <span class="arrow-up"></span>
              {{ outcome.outcome_odd.toFixed(2) }}
              <span class="arrow-down"></span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>Non sono disponibili quote per questo evento</div>
  </div>
</template>
<script>
export default {
  data: () => {
    return { isMobile: false, mappedMarkets: [], markets: [] };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 760;
  },
  methods: {
    calcColspanEsiti(param) {
      var count = Object.keys(param).length;
      if (count > 4) return 4;
      else {
        return 12 / count;
      }
    },
    classMarketOutcome(marketListId, periodId, outcomeListId, outcomeOdd) {
      var key = marketListId + "_" + periodId + "_" + outcomeListId;
      var returnClass = "";
      if (this.mappedMarkets[key]) {
        if (this.mappedMarkets[key] > outcomeOdd) {
          returnClass = "down";
        } else if (this.mappedMarkets[key] < outcomeOdd) {
          returnClass = "up";
        }
      }
      this.mappedMarkets[key] = outcomeOdd;
      return returnClass;
    },
    setMarkets(markets, emptyMap = false) {
      this.markets = markets;
      if (emptyMap) this.mappedMarkets = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

#quoteComponent {
  border: 4px solid gray;
  background-color: gray;
}
.row {
  margin-right: 0;
  margin-left: 0;
}

.scommessa {
  background-color: $primary;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.accent-border-top {
  border-top: 3px solid $accent-darker;
}

.periodo {
  color: white;
  text-transform: uppercase;
}

.esito {
  display: flex;
  flex-direction: row;
  border-top: 1px solid transparent;
  .nome {
    width: 60%;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    background-color: $light-bg-darker-30;
    &::after {
      left: 60%;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 100%;
      width: 100%;
      position: absolute;
      pointer-events: none;
      border-left-color: $light-bg-darker-30;
      border-width: 19px;
      margin-top: 0;
    }
  }

  .quota {
    width: 40%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: $light-bg-darker-10;
    &:not(.deleted) {
      &.up {
        .arrow-down {
          display: none;
        }

        .arrow-up {
          display: block;
        }
      }

      &.down {
        .arrow-down {
          display: block;
        }

        .arrow-up {
          display: none;
        }
      }
    }
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid green;
    display: none;
    position: absolute;
    bottom: 40%;
    right: 8px;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid red;
    display: none;
    position: absolute;
    top: 40%;
    right: 8px;
  }
}
</style>