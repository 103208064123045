<template>
  <b-row id="subnavbarComponent" toggleable="lg" sticky class="row sticky-top">
    <b-col
      v-for="(route, index) in routes"
      :key="index"
      @click="pushRoute(route.name)"
      :class="[checkCurrentRoute(route.name), route.bg]"
      :cols="6"
      :md="3"
    >
      {{ route.label[$t("locale")] }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  data: () => {
    return {
      routes: [
        {
          label: { en: "SCHEDULE", it: "PALINSESTO" },
          name: "home",
          bg: "white",
        },
        {
          label: { en: "SCORES", it: "RISULTATI" },
          name: "scores",
          bg: "yellow",
        },
        {
          label: { en: "SHOP DEMO", it: "VISUALIZZAZIONE" },
          name: "monitor",
          bg: "green",
        },
        {
          label: { en: "TERMS", it: "REGOLAMENTI" },
          name: "regolamenti",
          bg: "black",
        },
        /*{
          label: "PALINSESTO",
          name: "stats",
          bg: "yellow",
        },
        {
          label: "EJOY CHANNEL",
          name: "canale",
          bg: "green",
        },
        {
          label: "RETAIL",
          name: "monitor",
          bg: "black",
        },*/
      ],
    };
  },
  methods: {
    pushRoute(routeName) {
      if (this.$route.name != routeName) {
        this.$router.push({
          name: routeName,
        });
      }
    },
    checkCurrentRoute(routeName) {
      return this.$route.name == routeName ? "active" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";
#subnavbarComponent {
  min-height: 5vh;
  z-index: 3;

  &.sticky-top {
    top: 10vh;
  }
  & > div {
    min-height: 5vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid grey;
    font-weight: bold;
    font-size: 12px;
    &.active {
      border-color: $body-bg;
      //filter: brightness(60%);
    }
    &:hover:not(.active) {
      filter: brightness(80%);
    }
    &.green {
      background-color: $accent;
    }
    &.yellow {
      background-color: $warn;
    }
    &.white {
      background-color: white;
    }
    &.black {
      background-color: $primary-darker;
      color: white;
    }
  }
}
</style>