<template>
  <div id="avvenimentiComponent" class="row my-4">
    <div class="col-md-4">
      <b-row id="layout-btn" no-gutters>
        <b-col
          cols="6"
          @click="layoutMenu = 'list'"
          :class="layoutMenu == 'list' ? 'active' : ''"
        >
          {{ $t("locale") == "en" ? "HOUR" : "ORARIO" }}
        </b-col>
        <b-col
          cols="6"
          @click="layoutMenu = 'grouped'"
          :class="layoutMenu == 'grouped' ? 'active' : ''"
        >
          {{ $t("locale") == "en" ? "TOURNAMENT" : "TORNEO" }}
        </b-col>
      </b-row>

      <MenuGrouped
        v-if="layoutMenu == 'grouped'"
        :subject="$t('locale') == 'en' ? 'events' : 'avvenimenti'"
        :loading="loading"
        :items="groupedAvvenimenti()"
        :isMobile="isMobile"
        :selectedItem="selectedAvvenimento"
        hideChannel
        @select="selectAvvenimento($event)"
      ></MenuGrouped>

      <Menu
        v-if="layoutMenu == 'list'"
        :subject="$t('locale') == 'en' ? 'events' : 'avvenimenti'"
        :loading="loading"
        :items="avvenimenti"
        :isMobile="isMobile"
        :selectedItem="selectedAvvenimento"
        @select="selectAvvenimento($event)"
      ></Menu>
    </div>

    <div class="col-md-8" v-if="selectedAvvenimento">
      <div v-if="selectedAvvenimento.is_live">
        <iframe
          v-if="selectedAvvenimento.streaming_link"
          :src="getStremingLink(selectedAvvenimento.streaming_link)"
          class="w-100"
          style="height: 57vh"
          frameBorder="0"
        ></iframe>
      </div>
      <div class="intestazione">
        {{ selectedAvvenimento.description }}
        <span v-if="selectedAvvenimento.is_live" class="live">LIVE</span>
        <span v-else class="prematch">PREMATCH</span>
      </div>
      <Quote ref="Quote"></Quote>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/schedule/Menu.vue";
import MenuGrouped from "@/components/schedule/MenuGrouped.vue";
import Quote from "@/components/schedule/Quote.vue";
import api from "@/services/api";

export default {
  data: () => {
    return {
      loading: false,
      live: {},
      prematch: {},
      avvenimenti: {},
      selectedAvvenimento: null,
      interval: null,
      channels: {},
      layoutMenu: "list",
    };
  },
  components: {
    Menu,
    MenuGrouped,
    Quote,
  },

  mounted() {
    api.getCanali((response) => {
      this.channels = response;
    });
    this.init(true);
    this.interval = setInterval(() => this.init(), 10 * 1000);
  },
  computed: {
    isMobile: () => window.innerWidth <= 760,
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  methods: {
    getStremingLink(streamingLink) {
      return streamingLink + "&parent=" + window.location.hostname;
    },
    groupedAvvenimenti() {
      var arr = this._.groupBy(this.avvenimenti, "tournament_id");
      var arr2 = [];

      this._.forEach(arr, (eventList, tournamentId) => {
        var el = this._.find(eventList, { is_live: 1 });
        if (!this._.isUndefined(el)) {
          // metto in cima all'array le manifestazioni con almeno un evento live
          arr2.unshift(arr[tournamentId]);
        } else {
          // metto in fondo quelle che non hanno live
          arr2.push(arr[tournamentId]);
        }
      });
      return arr2;
    },
    init(showLoading = false) {
      if (showLoading) this.loading = true;
      api.getAvvenimenti(
        (res) => {
          this.avvenimenti = res;
          if (res.length > 0) {
            var exists = false;
            if (this.selectedAvvenimento)
              res.forEach((element) => {
                if (element.id == this.selectedAvvenimento.id) {
                  exists = true;
                  this.selectAvvenimento(element, false);
                }
              });
            if (!exists) this.selectAvvenimento(res[0], false);
          }
          if (showLoading) this.loading = false;
        },
        0,
        "web"
      );
    },
    selectChannel(channelId) {
      var avv = this._.head(
        this._.filter(this.avvenimenti, { channel_id: channelId })
      );
      this.selectAvvenimento(avv);
    },
    selectAvvenimento(avvenimento, scrollTopAndReset = true) {
      if (
        !this.selectedAvvenimento ||
        this.selectedAvvenimento.id != avvenimento.id
      )
        this.selectedAvvenimento = avvenimento;
      if (scrollTopAndReset) window.scroll({ top: 0, behavior: "smooth" });
      this.getAvvenimento(avvenimento, scrollTopAndReset);
    },
    getAvvenimento(avvenimento, clearMarkets) {
      api.getAvvenimento((res) => {
        this.$nextTick(() => {
          if (this.$refs["Quote"])
            if (res && res.markets)
              this.$refs["Quote"].setMarkets(res.markets, clearMarkets);
            else this.$refs["Quote"].setMarkets(null, clearMarkets);
        });
      }, avvenimento.id);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/variables";
#avvenimentiComponent {
  min-height: 80vh;
  font-size: 12px;
}

@media (max-width: 760px) {
  #avvenimentiComponent {
    min-height: 40vh;
  }
}

#layout-btn {
  margin-bottom: 5px;
  & > div {
    min-height: 5vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid grey;
    font-weight: bold;
    font-size: 15px;
    background-color: $warn;
    &.active {
      background-color: $accent;
    }
    &:hover:not(.active) {
      filter: brightness(80%);
    }
  }
}

.intestazione {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  background-color: #172324;
  color: white;
  padding: 2vh 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .prematch {
    background-color: grey;
    font-size: 15px;
    padding: 2px 6px;
    border-radius: 5px;
  }
  .live {
    background-color: red;
    font-size: 15px;
    padding: 2px 6px;
    border-radius: 5px;
  }
}

.mini-player {
  position: fixed;
  bottom: 10px;
  z-index: 10;
  width: 436px !important;
  right: 0;
  height: 25vh;
}

#channels-btn {
  //min-height: 5vh;

  & > div {
    min-height: 8vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid grey;
    font-weight: bold;
    font-size: 15px;
    background-color: $warn;
    &.active {
      background-color: $accent;
      border-right-width: 2px;
    }
    &:hover:not(.active) {
      filter: brightness(80%);
    }
  }
}
</style>
