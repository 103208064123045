<template>
  <div id="scoresPage">
    <div class="row">
      <div class="col-12 col-md-4">
        <b-form-datepicker
          id="custom-datepicker"
          v-model="selectedDate"
          class="mb-2"
          @input="getScores()"
        ></b-form-datepicker>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-6">
        <b-table-simple
          :sticky-header="'70vh'"
          bordered
          responsive
          striped
          style="overflow-y: scroll"
        >
          <b-thead>
            <b-tr style="height: 5vh">
              <b-th style="width: 50px" class="text-center"> {{ $t("locale") == "en" ? "HOUR" : "ORA" }}</b-th>
              <b-th style="min-width: 200px">MATCH</b-th>
              <b-th style="width: 100px" class="text-center"> {{ $t("locale") == "en" ? "SCORE" : "RISULTATO" }}</b-th>
              <b-th style="width: 50px"> </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="event in scoresData"
              :key="event.id"
              @click="selectedEvent = event"
              class="event"
              :class="
                selectedEvent !== null && event.id == selectedEvent.id
                  ? 'active'
                  : ''
              "
            >
              <b-td class="text-right">
                {{ event.begin | moment("timezone", "Europe/Rome", "H:mm") }}
              </b-td>
              <b-td> {{ event.description }} </b-td>
              <b-td class="text-center" style="font-size: 13px">
                <b>{{ event.result_total }} </b></b-td
              >
              <b-td>
                <b-icon
                  stacked
                  icon="chevron-right"
                  style="width: 20px; float: right"
                ></b-icon>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div
        class="col-6"
        style="overflow-y: scroll; overflow-x: hidden; height: 70vh"
      >
        <div class="row no-gutters" v-if="selectedEvent">
          <div
            class="col-12"
            v-for="market in selectedEvent.markets"
            :key="market.uid"
          >
            <div class="row no-gutters scommessa">
              <b>{{ market.market_name }}</b> {{ market.param_1 }}
            </div>
            <div
              class="row no-gutters periodo"
              v-for="period in market.periods"
              :key="period.period_id"
            >
              <div class="col-12">
                {{ period.period_name }}
                <div class="row no-gutters">
                  <div
                    class="col esito"
                    v-for="outcome in period.outcomes"
                    :key="outcome.outcome_id"
                  >
                    <div class="nome">{{ outcome.outcome_name }}</div>
                    <div class="quota">{{ outcome.result }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  data: () => {
    return {
      selectedDate: null,
      scoresData: [],
      selectedEvent: null,
    };
  },
  components: {},

  mounted() {
    if (this._.isNull(this.selectedDate))
      this.selectedDate = this.$moment().format("YYYY-MM-DD");
    this.getScores();
  },
  computed: {
    isMobile: () => window.innerWidth <= 760,
  },

  methods: {
    getScores() {
      api.getScores((d) => {
        this.scoresData = d;
        this.selectedEvent = d[Object.keys(d)[0]];
      }, this.selectedDate);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/variables";
#scoresPage {
  min-height: 70vh;
  font-size: 12px;

  .b-table-sticky-header {
    overflow-x: hidden;
    > .table.b-table > thead tr {
      position: sticky;
      th {
        background-color: $primary;
        color: white;
        vertical-align: middle;
        border: 2px solid #dee2e6;
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
    tbody tr.event {
      cursor: pointer;
      &:nth-child(odd)r {
        background-color: white;
      }
      &.active {
        background-color: $accent;
      }
    }
    tbody + tbody {
      border-top: 1px solid #dee2e6;
    }
  }

  .scommessa {
    background-color: $primary;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    justify-content: center;
  }

  .periodo {
    color: white;
    text-transform: uppercase;
    border-top: 3px solid $accent-darker;
    background-color: gray;
    text-align: center;
  }
  .esito {
    display: flex;
    flex-direction: row;
    border-top: 1px solid transparent;
    color: $primary;
    .nome {
      width: 60%;
      padding: 10px;
      text-align: center;
      white-space: nowrap;
      background-color: $light-bg-darker-30;
      &::after {
        left: 60%;
        top: 0;
        border: solid transparent;
        content: " ";
        height: 100%;
        width: 100%;
        position: absolute;
        pointer-events: none;
        border-left-color: $light-bg-darker-30;
        border-width: 19px;
        margin-top: 0;
      }
    }

    .quota {
      width: 40%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      background-color: $light-bg-darker-10;
    }
  }
}

@media (max-width: 760px) {
  #scoresPage {
    min-height: 40vh;
  }
}
</style>
