import Vue from "vue";
import router from "../router";

function setHeaders() {
  let user = JSON.parse(localStorage.getItem("user"));
  return { Authorization: "Bearer " + (user ? user.web_token : "") };
}

export default {
  sendGet: (endpoint, data = {}) => {
    return Vue.axios
      .get(endpoint, {
        params: data,
        headers: setHeaders(),
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          router.push({
            name: "login",
          });
        }
      });
  },
  sendPost: (endpoint, data = {}) => {
    return Vue.axios
      .post(endpoint, data, { headers: setHeaders() })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          router.push({
            name: "login",
          });
        }
        return { error: error.response.data };
      });
  },
};
