var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-3",attrs:{"id":"statisticheComponent"}},[(_vm.layout == 'palinsesto')?_c('b-row',[_c('b-col',{attrs:{"md":2}},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"head"},[_vm._v("DISCIPLINE")]),_vm._l((_vm.discipline),function(disciplina){return _c('div',{key:disciplina.sport_id,class:_vm.classSelectedDisciplina(disciplina.sport_id),on:{"click":function($event){return _vm.selectDisciplina(disciplina.sport_id)}}},[_vm._v(" "+_vm._s(disciplina.sport_name)+" ")])})],2)]),_c('b-col',[_c('b-table-simple',{staticStyle:{"overflow-y":"scroll"},attrs:{"sticky-header":'70vh',"bordered":"","responsive":"","striped":""}},[(_vm.headerMarketOutcomeIds)?_c('b-thead',[_c('b-tr',{staticStyle:{"height":"5vh"}},[_c('b-th',{staticStyle:{"width":"50px"},attrs:{"rowspan":"2"}},[_vm._v("ORA")]),_c('b-th',{staticStyle:{"min-width":"200px"},attrs:{"rowspan":"2"}},[_vm._v("MATCH")]),_vm._l((_vm.headerMarketOutcomeIds),function(headerMarketOutcomeId,indexHeaderMarOutIds){return _c('b-th',{key:indexHeaderMarOutIds,staticClass:"d-none d-md-table-cell",staticStyle:{"text-align":"center","text-transform":"uppercase"},attrs:{"colspan":headerMarketOutcomeId.colspan}},[_vm._v(" "+_vm._s(_vm.marketLabels[parseInt(headerMarketOutcomeId.market)])+" ")])}),_c('b-th',{attrs:{"rowspan":"2"}})],2),_c('b-tr',{staticStyle:{"height":"5vh","top":"5vh"}},_vm._l((_vm.marketOutcomeIds[
                  _vm.selectedDisciplina
                ]),function(marketOutcomeId,indexMarOutIds){return _c('b-th',{key:indexMarOutIds,staticClass:"d-none d-md-table-cell",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(marketOutcomeId.outcomeLabel)+" ")])}),1)],1):_vm._e(),_vm._l((_vm.sortEventi(
              _vm.avvenimenti[_vm.selectedDisciplina]
            )),function(eventi,tournament_id){return _c('b-tbody',{key:tournament_id},[_c('b-tr',{on:{"click":function($event){return _vm.addOrRemove(tournament_id)}}},[_c('b-td',{attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(eventi[0].tournament_name)+" "),_c('b-icon',{staticStyle:{"width":"20px","float":"right"},attrs:{"stacked":"","icon":"chevron-down"}})],1)],1),_vm._l((eventi),function(evento){return _c('b-tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkTest(tournament_id)),expression:"checkTest(tournament_id)"}],key:evento.id},[(evento.is_live)?_c('b-td',{staticClass:"align-middle"},[_c('span',{staticClass:"live"},[_vm._v("LIVE")])]):_c('b-td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm._f("moment")(evento.begin,"timezone", "Europe/Rome", "DD/MM H:mm"))+" ")]),_c('b-td',{staticClass:"align-middle"},[_vm._v(_vm._s(evento.description))]),_vm._l((_vm.marketOutcomeIds[
                  _vm.selectedDisciplina
                ]),function(marketOutcomeId,indexMarOutIds){return _c('b-td',{key:indexMarOutIds,staticClass:"d-none d-md-table-cell quota",class:[
                  _vm.classMarketOutcome(evento, marketOutcomeId),
                  marketOutcomeId.param ? '' : 'font-weight-bold' ]},[_c('span',{staticClass:"arrow-up"}),_vm._v(" "+_vm._s(_vm.getMarketOutcome(evento.markets, marketOutcomeId))+" "),_c('span',{staticClass:"arrow-down"})])}),_c('b-td',{staticClass:"more align-middle",on:{"click":function($event){return _vm.selectAvvenimento(evento)}}},[_c('b-icon',{attrs:{"icon":"plus-circle","variant":"primary"}})],1)],2)})],2)})],2)],1)],1):_vm._e(),(_vm.layout == 'scores')?_c('b-row',[_c('b-col',[_c('Scores')],1)],1):_vm._e()],1),_c('b-modal',{staticClass:"p-0",attrs:{"id":"overlayAvvenimento","size":"lg","hide-footer":"","headerClass":'p-2 background-primary text-center',"header-close-variant":'light',"titleClass":'w-100',"bodyClass":'p-0',"modalClass":'overflow-auto mt-5'},on:{"shown":function($event){return _vm.setQuoteMarkets()},"hidden":function($event){return _vm.resetQuoteInterval()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"text-light"},[_vm._v(" "+_vm._s(_vm.selectedAvvenimento.description)+" "),_c('br'),_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.selectedAvvenimento.result_total)+" ")])])]},proxy:true}])},[_c('div',{staticStyle:{"overflow-y":"scroll","height":"80vh","overflow-x":"hidden"}},[_c('Quote',{ref:"QuoteModal"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }