var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"quoteComponent"}},[(_vm.markets)?_c('div',_vm._l((_vm.markets),function(market,marketId){return _c('div',{key:marketId},[_c('b-row',[_c('b-col',{staticClass:"scommessa"},[_vm._v(" "+_vm._s(market.market_name)+" ")])],1),_vm._l((market.periods),function(period,periodIndex){return _c('b-row',{key:periodIndex,staticClass:"no-gutters"},[_c('b-col',{staticClass:"periodo accent-border-top text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(period.period_name)+" ")]),_vm._l((period.outcomes),function(outcome,outcomeId){return _c('b-col',{key:outcomeId,staticClass:"esito",style:(period.status != 2 ||
            outcome.outcome_status == 4 ||
            outcome.outcome_status == 5
              ? 'opacity: 0.4'
              : ''),attrs:{"md":_vm.calcColspanEsiti(period.outcomes)}},[_c('div',{staticClass:"nome"},[_vm._v(" "+_vm._s(outcome.outcome_name)+" "+_vm._s(outcome.outcome_param_1)+" ")]),_c('div',{staticClass:"quota",class:[
              _vm.classMarketOutcome(
                market.market_list_id,
                period.periods_id,
                outcome.outcome_list_id,
                outcome.outcome_odd
              ),

              period.status != 2 ||
              outcome.outcome_status == 4 ||
              outcome.outcome_status == 5
                ? 'deleted'
                : '' ]},[_c('span',{staticClass:"arrow-up"}),_vm._v(" "+_vm._s(outcome.outcome_odd.toFixed(2))+" "),_c('span',{staticClass:"arrow-down"})])])})],2)})],2)}),0):_c('div',[_vm._v("Non sono disponibili quote per questo evento")])])}
var staticRenderFns = []

export { render, staticRenderFns }