<template>
  <div class="w-100 facsimile-wrapper" style="height: 300px">
    <span class="facsimile">facsimile</span>
    <div v-if="live && live.is_live" class="page-container">
      <b-row class="d-flex align-content-center header">
        <b-col> {{ today | moment("timezone", "Europe/Rome", "H:mm") }} </b-col>
        <b-col class="text-center text-uppercase bold">
          {{ live.sport_name }}
        </b-col>
        <b-col class="text-right">
          <img src="@/assets/logo-scuro.png" style="height: 15px"
        /></b-col>
      </b-row>
      <b-row no-gutters class="description bg-white">
        <b-col
          class="d-flex align-center align-items-center justify-content-center"
        >
          <span>{{ live.description }}</span>
        </b-col>
      </b-row>

      <b-row class="body" no-gutters>
        <b-col class="bg-white">
          <div class="label-score">LIVE SCORE</div>
          <div class="score">
            <span v-if="live.result_total">
              <span>{{ live.result_total.split(" ")[0] }}</span>
            </span>
            <span v-else>0 - 0</span>
          </div>
          <div class="sub-score">
            <span v-if="live.result_total">
              {{ live.result_total.split(" ")[1] }}
            </span>
          </div>
        </b-col>
        <b-col class="bg-white">
          <b-row class="w-75 no-gutters">
            <b-col
              :cols="12"
              v-for="(group, index) in marketOutcomeIds[live.sport_id]"
              :key="index"
              style="padding: 2px"
            >
              <b-table-simple bordered style="background-color: white">
                <b-thead>
                  <b-tr>
                    <b-th :colspan="group.length">
                      {{ getMarketLabel(group[0].marketId) }}
                    </b-th>
                  </b-tr>
                  <b-tr style="background-color: #e8e8e8">
                    <b-th v-for="(mark, i) in group" :key="i">
                      {{ getOutcomeLabel(live.markets, mark) }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td v-for="(mark, i) in group" :key="i">
                      {{ getMarketOutcome(live.markets, mark) }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-else class="empty">L'evento live sta per iniziare...</div>
  </div>
</template>

<script>
import api from "@/services/api";

const marketOutcomeIds = {
  1: [
    [
      {
        marketId: 3,
        outcomeId: "1_",
      },
      {
        marketId: 3,
        outcomeId: "2_",
      },
      {
        marketId: 3,
        outcomeId: "3_",
      },
    ],
    [
      {
        marketId: 10055,
        outcomeId: "37_1.5",
        param: true,
      },
      {
        marketId: 10055,
        outcomeId: "37_1.5",
      },
      {
        marketId: 10055,
        outcomeId: "38_1.5",
      },
    ],
  ],
  2: [
    [
      {
        marketId: 2,
        outcomeId: 39,
      },
      {
        marketId: 2,
        outcomeId: 40,
      },
    ],
    [
      {
        marketId: 10055,
        outcomeId: 37,
        param: true,
      },
      {
        marketId: 10055,
        outcomeId: 37,
      },
      {
        marketId: 10055,
        outcomeId: 38,
      },
    ],
  ],
};

export default {
  data: () => {
    return {
      //channelId: 0,
      descriptions: {},
      live: {},
      interval: null,
      marketOutcomeIds: marketOutcomeIds,
    };
  },
  props: ["avvenimento"],
  computed: {
    today: () => new Date(),
    // hostname: () => window.location.hostname,
    //isMobile: () => window.innerWidth <= 760,
  },
  mounted() {
    //  this.channelId = this.$route.params ? this.$route.params.channelId : 0;
    this.init();
    this.interval = setInterval(() => this.init(), 10 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  methods: {
    init() {
      /*  api.getAvvenimenti((data) => {
      var avvenimento = this._.find(data, {
          is_live: 1,
          channel_id: parseInt(this.channelId),
        });*/
      if (this.avvenimento) this.getAvvenimentoLive(this.avvenimento.id);
      // });
    },

    getAvvenimentoLive(idAvvenimento) {
      api.getAvvenimento((res) => {
        if (res.is_live) this.live = res;
        else this.init();
      }, idAvvenimento);
    },
    getOutcomeLabel(markets, marketOutcomeId) {
      if (marketOutcomeId.param) return "";

      var outcome =
        markets[marketOutcomeId.marketId].periods[1].outcomes[
          marketOutcomeId.outcomeId
        ];
      if (outcome) return outcome.outcome_name;

      return "";
    },
    getMarketOutcome(markets, marketOutcomeId) {
      var outcome =
        markets[marketOutcomeId.marketId].periods[1].outcomes[
          marketOutcomeId.outcomeId
        ];

      if (outcome)
        return marketOutcomeId.param
          ? outcome.outcome_param_1
          : outcome.outcome_odd.toFixed(2);

      return "-";
    },
    getMarketLabel(marketId) {
      if (!this.live || !this.live.markets || !this.live.markets[marketId])
        return "";
      return this.live.markets[marketId].market_name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.page-container {
  height: 100%;
  margin: auto;
}

.header {
  background-color: $accent-lighter;
  height: 12%;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px solid black;
  padding: 0 10px;
}

.description {
  background-color: $accent-lighter;
  font-size: 15px;
  height: 24%;
  font-weight: bold;
  padding: 2%;
}

.bg-white {
  background-color: white;
  border: 4px solid $accent-lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.body {
  background-color: $accent-lighter;
  height: 64%;
  border-bottom: 2px dotted $accent;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .label-score {
    font-size: 20px;
    font-weight: bold;
    color: $primary;
  }
  .score {
    font-weight: bold;
    color: $accent;
    font-size: 50px;
    line-height: 40px;
  }

  .sub-score {
    font-size: 14px;
    color: $accent;
    text-align: center;
    line-height: 20px;
  }
  table {
    text-align: center;
  }

  th {
    font-size: 8px;
    padding: 1px;
    text-transform: uppercase;
  }

  td {
    font-size: 10px;
    padding: 1px;
    width: 50px;
  }
}

.empty {
  height: 100%;
  background-color: $accent-lighter;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facsimile-wrapper {
  position: relative;
}
.facsimile {
  position: absolute;
  opacity: 0.1;
  left: 10%;
  bottom: 25%;
  z-index: 9;
  color: gray;
  font-size: 100px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
}
</style>

